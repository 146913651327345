<template>
  <div class="pagination">
    <v-btn
      @click="updatePage('prev')"
      :disabled="this.pagination.page === 1"
      class="control-button"
    >
      <v-icon color="white">mdi-chevron-left</v-icon>
    </v-btn>
    <div class="pagination-pages">
      <p
        @click="updatePage(page)"
        :class="
          activePage === page ? 'pagination-page-active' : 'pagination-page'
        "
        v-for="(page, index) in pagesDisplay"
        :key="index"
      >
        <span v-if="page !== '...'" style="cursor: pointer">{{ page }}</span>
        <span v-else>{{ page }}</span>
      </p>
    </div>
    <v-btn
      @click="updatePage('next')"
      :disabled="this.pagination.page === this.pagination.pages"
      class="control-button"
    >
      <v-icon color="white">mdi-chevron-right</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "CustomPagination",
  props: {
    pagination: {
      type: Object,
      required: true,
      validator(obj) {
        const properties = ["limit", "offset", "count", "page", "pages"];
        for (let p of properties) {
          if (typeof obj[p] !== "number") return false;
        }
        return true;
      },
    },
  },
  computed: {
    pagesDisplay() {
      let result = [];
      if (this.pagination.pages < 10) {
        for (let i = 1; i <= this.pagination.pages; i++) {
          result.push(i);
        }
      } else {
        for (let i = 1; i <= 9; i++) {
          if (i === 1) result.push(i);
          else if (i === 2) {
            if (this.pagination.page < 5) {
              result.push(i);
            } else result.push("...");
          } else if (i > 2 && i < 8) {
            if (
              this.pagination.page - 5 + i > i - 1 &&
              this.pagination.page - 5 + i < this.pagination.pages - 8 + i
            ) {
              result.push(this.pagination.page - 5 + i);
            } else if (this.pagination.page < 5) {
              result.push(i);
            } else if (this.pagination.pages - 9 + i) {
              result.push(this.pagination.pages - 9 + i);
            }
          } else if (i === 8) {
            if (this.pagination.page > this.pagination.pages - 5) {
              result.push(this.pagination.pages - 1);
            } else result.push("...");
          } else {
            result.push(this.pagination.pages);
          }
        }
      }
      return result;
    },
  },
  data() {
    return {
      activePage: 1,
    };
  },
  methods: {
    updatePage(value = "") {
      if (value === "...") return;
      let page = this.activePage;
      if (Number.isInteger(value)) page = value;
      else if (value === "prev") page -= 1;
      else if (value === "next") page += 1;
      this.activePage = page;
      this.$emit("updatePage", page);
    },
  },
};
</script>

<style scoped lang="scss">
.pagination {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .control-button {
    width: 32px;
    height: 32px;
    background: #3474d3;
    border-radius: 8px;
    overflow: hidden;
  }
  .control-button:first-child {
    margin-right: 20px;
  }
  .control-button:last-child {
    margin-left: 20px;
  }
  .pagination-pages {
    display: flex;
    flex-direction: row;
    align-items: center;

    .pagination-page,
    .pagination-page-active {
      margin: 0 10px !important;
      font-family: Open Sans, sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: -0.02em;
      color: #8e8e93;
      opacity: 0.5;
    }
    .pagination-page-active {
      color: #2c2c32;
    }
  }
}
.v-btn:not(.v-btn--round).v-size--default {
  min-width: 32px;
}
</style>
