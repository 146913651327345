<template>
  <v-app>
    <snack-bar />
    <left-navigation-drawer @toggleMainWidth="changeWidth($event)" />
    <v-main
      style="background: #f9f9f9"
      :class="active ? 'main-enter' : 'main-leave'"
    >
      <app-bar />
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import LeftNavigationDrawer from "@/components/LeftNavigationDrawer";
import AppBar from "@/components/AppBar";
import SnackBar from "@/components/SnackBar";
export default {
  name: "AuthorizedLayout",
  components: { SnackBar, AppBar, LeftNavigationDrawer },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    changeWidth(value) {
      value === "enter" ? (this.active = true) : (this.active = false);
    },
  },
};
</script>

<style scoped>
.main-enter {
  margin-left: 240px;
  background-color: #e5e5e5;
}
.main-leave {
  margin-left: 108px;
  background-color: #e5e5e5;
}
</style>
