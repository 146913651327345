<template>
  <v-snackbar class="pa-0" rounded="xl" color="white" v-model="snackbar.status">
    <template v-slot:default>
      <div class="snackbar">
        <div class="d-flex align-center">
          <img class="icon" :src="getImage()" alt="icon" />
          <p
            style="white-space: pre-line"
            class="ma-0 pa-0 font-weight-bold text-center"
          >
            {{ snackbar.text }}
          </p>
        </div>
        <v-btn @click="snackbar.status = false" icon>
          <v-icon class="pr-2" text color="red"> mdi-close-thick </v-icon>
        </v-btn>
      </div>
    </template>
  </v-snackbar>
</template>

<script>
export default {
  name: "SnackBar",
  data: () => ({
    snackbar: {
      status: false,
      color: "",
      text: "",
    },
  }),
  methods: {
    getImage() {
      if (this.snackbar.color === "success")
        return require(`@/assets/svg/checked-icon.svg`);
      return require(`@/assets/svg/alert-icon.svg`);
    },
  },
  created() {
    this.$root.$on("showCustomSuccess", (text) => {
      this.snackbar.status = false;
      this.snackbar.text = text;
      this.snackbar.color = "success";
      this.snackbar.status = true;
      this.snackbar.image = "checked-icon";
    });

    this.$root.$on("showCustomError", (text) => {
      this.snackbar.status = false;
      this.snackbar.text = text;
      this.snackbar.color = "error";
      this.snackbar.status = true;
      this.snackbar.image = "alert-icon";
    });

    this.$root.$on("showDefaultError", () => {
      this.snackbar.status = false;
      this.snackbar.text = "Произошла ошибка во время получения данных";
      this.snackbar.color = "error";
      this.snackbar.status = true;
      this.snackbar.image = "alert-icon";
    });
  },
};
</script>

<style scoped lang="scss">
.snackbar {
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .icon {
    width: 40px;
    height: 40px;
    margin-left: 15px;
  }
  p {
    margin-left: 15px !important;
    font-family: Gilroy-Medium, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #3474d3;
  }
}
</style>
