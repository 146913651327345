<template>
  <v-app>
    <SnackBar />
    <router-view />
  </v-app>
</template>

<script>
import SnackBar from "../components/SnackBar";
export default {
  name: "NotAuthorizedLayout",
  components: { SnackBar },
};
</script>

<style scoped>
.v-app {
  width: 100vw;
  height: 100vh;
}
</style>
