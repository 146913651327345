<template>
  <div class="page-not-found">
    <img src="@/assets/svg/page-not-found.svg" alt="page not found" />
    <img
      style="margin-top: 20px"
      src="@/assets/svg/page-not-found-logo.svg"
      alt="biometric logo"
    />
    <p class="not-found-text">
      Данная страница не найдена<br />
      или была удалена
    </p>
    <v-btn @click="$router.push({ name: 'home' })" class="to-home"
      >На главную</v-btn
    >
  </div>
</template>

<script>
export default {
  name: "PageNotFound",
};
</script>

<style scoped lang="scss">
.page-not-found {
  margin: 60px auto;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;

  .not-found-text {
    margin-top: 40px !important;
    font-family: "Gilroy-Regular", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 31px;
    text-align: center;
    color: #3474d3;
  }
  .to-home {
    margin-top: 70px;
    font-family: "Gilroy-Bold", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    color: #ffffff;
    text-transform: none;
    background: #3474d3;
    box-shadow: 0 2px 10px rgba(52, 116, 211, 0.3);
    border-radius: 8px;
  }
}
</style>
